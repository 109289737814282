function isNumber(value: string) {
  return !isNaN(Number(value));
}

export default function NumberInput({
  value,
  hasError = false,
  handleInputChange,
  handleKeyUp,
}: {
  value?: number;
  hasError?: boolean;
  handleInputChange?: (value: number) => void;
  handleKeyUp?: (key: string) => void;
}) {
  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = event.target.value;
    if (handleInputChange && isNumber(value)) {
      handleInputChange(Number(value));
    }
  }

  return (
    <input
      value={value}
      className={`input${hasError ? " error-input" : ""}`}
      type="text"
      disabled={!handleInputChange}
      onChange={onInputChange}
      onKeyUp={(event) => handleKeyUp && handleKeyUp(event.key)}
    />
  );
}
