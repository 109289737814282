import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppStore } from "types";
import { StorageName } from "utils/constants/storage";
import { localStore } from "utils/helpers/storage";

const initialState: AppStore = {
  token: localStore.get(StorageName.CUSTOMER_TOKEN) || "",
  error: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      if (!!action.payload) {
        localStore.save(StorageName.CUSTOMER_TOKEN, action.payload);
      } else {
        localStore.remove(StorageName.CUSTOMER_TOKEN);
      }
      state.token = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const slice = appSlice.reducer;
export const actions = appSlice.actions;
