import "./OutdatedSettings.scss";

import { useTranslation } from "react-i18next";

import { ReactComponent as AlertIcon } from "assets/img/icons/alert-triangle.svg";

export default function OutdatedSettings({ isOutdated }: { isOutdated: boolean }) {
  const { t } = useTranslation();

  if (!isOutdated) {
    return <p></p>;
  }

  return (
    <p className="outdated-settings-section">
      <AlertIcon />
      <span className="outdated-settings-text">
        {t("List.SettingsNotUpdated")}
        <span className="info-hover-text">{t("List.SettingsNotUpdatedInfo")}</span>
      </span>
    </p>
  );
}
