import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomSelector } from "store/useStore";

import Header from "components/ui/Header";
import UnitList from "components/ui/UnitList";
import ErrorContent from "components/ui/Error";

import BackendService from "services/backend";

import { Unit } from "types";

import "./Home.scss";

export default function Home() {
  const { t } = useTranslation();

  const { token, error } = useCustomSelector((state) => state.app);

  const navigate = useNavigate();

  const [units, setUnits] = useState<Unit[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate("/credentials");
    }
  }, [token, navigate]);

  const loadUnits = useCallback(async () => {
    let unitsResponse = await BackendService.getUnits();
    if (unitsResponse.isSuccess()) {
      setUnits(unitsResponse.data);
    }
  }, []);

  useEffect(() => {
    if (!token) {
      return;
    }

    setIsLoading(true);
    loadUnits();
    setIsLoading(false);

    let interval = setInterval(loadUnits, 1000 * 60 * 2);

    return () => {
      clearInterval(interval);
    };
  }, [token, loadUnits]);

  if (error) {
    return (
      <div className="error-page">
        <ErrorContent />
      </div>
    );
  }

  return (
    <div className="home-page">
      <Header />
      <UnitList units={units} handleUnitRefresh={loadUnits} />
      {isLoading && <p className="loading-text">{t("Loading")}</p>}
      {!isLoading && units.length === 0 && <p className="empty-list-text">{t("List.Empty")}</p>}
    </div>
  );
}
