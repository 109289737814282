import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import OutdatedSettings from "./OutdatedSettings";

import { Unit } from "types";

const minuteInMiliseconds = 60 * 1000;

export default function ListItem({ unit, handleUnitClick }: { unit: Unit; handleUnitClick: () => void }) {
  const { t } = useTranslation();

  const lastSeen = useMemo(() => {
    if (unit.lastSeenUtc.includes("0001-01-01")) {
      return t("List.Never");
    }

    let lastSeenTimestamp = new Date(`${unit.lastSeenUtc}Z`);
    let now = new Date();

    if (now.valueOf() - lastSeenTimestamp.valueOf() < minuteInMiliseconds * 5) {
      return t("List.Now");
    }

    if (lastSeenTimestamp.getFullYear() === now.getFullYear() && lastSeenTimestamp.getMonth() === now.getMonth() && lastSeenTimestamp.getDate() === now.getDate()) {
      // Today - show timestamp only
      return lastSeenTimestamp.toLocaleTimeString();
    }

    // We last saw the terminal yesterday or before - show full date time
    return lastSeenTimestamp.toLocaleString();
  }, [unit.lastSeenUtc, t]);

  const settingsAreUpdated = useMemo(() => {
    return unit.settingHash === unit.generatedSettingHash;
  }, [unit]);

  return (
    <div className="list-item" onClick={handleUnitClick}>
      <p>{unit.name}</p>
      <OutdatedSettings isOutdated={!settingsAreUpdated} />
      <p>{lastSeen}</p>
    </div>
  );
}
