import { siteStorageName } from "utils/constants/storage";

type StorageType = "local" | "session";

const loadStorage = (storageType: StorageType) => {
  try {
    let serializedState: string | null = null;
    if (storageType === "local") {
      serializedState = localStorage.getItem(siteStorageName);
    } else if (storageType === "session") {
      serializedState = sessionStorage.getItem(siteStorageName);
    }

    if (serializedState === null) {
      return undefined;
    }

    return serializedState ? JSON.parse(serializedState) : serializedState;
  } catch (e) {
    return undefined;
  }
};

const commitToStorage = (storageType: StorageType, storageObject: any) => {
  try {
    let serializedState = JSON.stringify(storageObject, function replacer(key, value) {
      return value;
    });
    if (storageType === "local") {
      localStorage.setItem(siteStorageName, serializedState);
    } else if (storageType === "session") {
      sessionStorage.setItem(siteStorageName, serializedState);
    }
  } catch (e) {
    console.error(e);
  }
};

const getFromStorage = (storageType: StorageType, key: string) => {
  let storageObject = loadStorage(storageType);
  return storageObject !== undefined ? storageObject[key] : undefined;
};

const saveToStorage = (storageType: StorageType, key: string, value: string | number | boolean) => {
  let storageObject = loadStorage(storageType) || {};
  storageObject[key] = value;
  commitToStorage(storageType, storageObject);
};

export const removeFromStorage = (storageType: StorageType, key: string) => {
  let storageObject = loadStorage(storageType) || {};
  delete storageObject[key];
  commitToStorage(storageType, storageObject);
};

const clearStorage = (storageType: StorageType) => {
  commitToStorage(storageType, {});
};

export const localStore = {
  get: (key: string) => getFromStorage("local", key),
  save: (key: string, value: any) => saveToStorage("local", key, value),
  remove: (key: string) => removeFromStorage("local", key),
  clear: () => clearStorage("local"),
};

export const sessionStore = {
  get: (key: string) => getFromStorage("session", key),
  save: (key: string, value: any) => saveToStorage("session", key, value),
  remove: (key: string) => removeFromStorage("session", key),
  clear: () => clearStorage("session"),
};
