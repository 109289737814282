import { Route, Routes, Navigate } from "react-router";

import Home from "components/home";
import Credentials from "components/credentials";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/credentials" element={<Credentials />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
