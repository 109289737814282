import HttpSuccessResponse from "./HttpSuccessResponse";

export default class HttpErrorResponse {
  status: number;
  error: string;

  constructor(status: number, error: string[] | string) {
    this.status = status;

    if (error instanceof Array) {
      this.error = error.join(", ");
    } else {
      this.error = error;
    }
  }

  isSuccess(): this is HttpSuccessResponse<never> {
    return false;
  }
}
