import { ReactNode } from "react";

import "./Modal.scss";

export default function Modal({ children }: { children: ReactNode | ReactNode[] }) {
  return (
    <div className="modal-wrapper">
      <div className="modal-background" />
      <div className="modal-content">{children}</div>
    </div>
  );
}
