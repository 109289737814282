import Button from "components/ui/Button";

import "./Error.scss";

export default function ErrorContent() {
  let appInsightsTraceId = window.appInsights?.context?.telemetryTrace?.traceID;

  function handleButtonClick() {
    window.location.replace(window.location.origin);
  }

  return (
    <div className="error-content">
      <h1 className="error-title">Beklager, der er sket en fejl</h1>
      {!!appInsightsTraceId && <p className="insights-trace-text">{appInsightsTraceId}</p>}
      <Button onClick={handleButtonClick}>Tilbage til forsiden</Button>
    </div>
  );
}
