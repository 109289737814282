import "./Toggle.scss";

export default function Toggle({ toggleId, label, isChecked, handleToggleClick }: { toggleId: string; label: string; isChecked: boolean; handleToggleClick: (updatedIsChecked: boolean) => void }) {
  function handleClick(event: any) {
    event.preventDefault();
    handleToggleClick(!isChecked);
  }

  return (
    <div className="toggle-wrapper">
      <div className="toggle" onClick={handleClick}>
        <input type="checkbox" id={toggleId} checked={isChecked} readOnly />
        <label htmlFor={toggleId}>Toggle</label>
      </div>
      <p>{label}</p>
    </div>
  );
}
