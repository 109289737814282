import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCustomDispatch } from "store/useStore";
import { appActions } from "store/app";

import { TextInput } from "components/ui/forms";
import Button from "components/ui/Button";

import "./Credentials.scss";

export default function Credentials() {
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();
  const navigate = useNavigate();

  const [tokenInput, setTokenInput] = useState("");

  function handleTokenButtonClick() {
    dispatch(appActions.setToken(tokenInput));
    navigate("/");
  }

  function handleKeyUp(key: string) {
    if (key === "Enter") {
      handleTokenButtonClick();
    }
  }

  return (
    <div className="token-page">
      <div className="token-content">
        <h1>{t("Token.Title")}</h1>
        <TextInput key="token" value={tokenInput} handleInputChange={(value) => setTokenInput(value)} handleKeyUp={handleKeyUp} />
        <Button attributes={{ disabled: !tokenInput }} onClick={handleTokenButtonClick}>
          {t("Continue")}
        </Button>
      </div>
    </div>
  );
}
