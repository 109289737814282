import { useState } from "react";
import { useTranslation } from "react-i18next";

import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import UnitEdit from "./Edit";
import Button from "components/ui/Button";

import { Unit } from "types";

import "./List.scss";

export default function List({ units, handleUnitRefresh }: { units: Unit[]; handleUnitRefresh: () => void }) {
  const { t } = useTranslation();

  const [editingUnit, setEditingUnit] = useState<Partial<Unit> | null>(null);

  function handleDismissEdit() {
    setEditingUnit(null);
    handleUnitRefresh();
  }

  if (editingUnit) {
    return <UnitEdit unit={editingUnit} dismiss={handleDismissEdit} handleFinalizedEdit={handleDismissEdit} />;
  }

  return (
    <div className="list">
      <ListHeader />
      {units.map((unit) => (
        <ListItem key={unit.id} unit={unit} handleUnitClick={() => setEditingUnit(unit)} />
      ))}
      <Button className="create-button" onClick={() => setEditingUnit({})}>
        {t("List.Create")}
      </Button>
    </div>
  );
}
