import { request } from "http/client";
import { getToken, getEnvironment } from "utils/helpers/service";

import { Patch, Unit, UnitSetting, Settings, UnitSetupSession } from "types";

const api = process.env.REACT_APP_BACKEND_URI || `api.${getEnvironment()}selfy.ninja/api`;

const BackendService = {
  getUnits: async () => {
    return request<Unit[]>("GET", `${api}/Unit`, { token: getToken() });
  },
  createUnit: async (payload: Unit) => {
    return request<Unit>("POST", `${api}/Unit`, { token: getToken(), payload });
  },
  updateUnit: async (unitId: string, payload: Patch[]) => {
    return request<Unit>("PATCH", `${api}/Unit/${unitId}`, { token: getToken(), payload });
  },
  getUnitSetting: async (unitId: string) => {
    return request<UnitSetting>("GET", `${api}/UnitSetting/${unitId}`, { token: getToken() });
  },
  createUnitSetting: async (payload: UnitSetting) => {
    return request<UnitSetting>("POST", `${api}/UnitSetting`, { token: getToken(), payload });
  },
  updateUnitSetting: async (unitId: string, payload: Patch[]) => {
    return request<UnitSetting>("PATCH", `${api}/UnitSetting/${unitId}`, { token: getToken(), payload });
  },
  createUnitSetupSession: async (unitId: string) => {
    return request<UnitSetupSession>("POST", `${api}/Settings`, { token: getToken(), payload: unitId });
  },
  getSettings: async (unitId: string) => {
    return request<Settings>("GET", `${api}/Settings/${unitId}`, { token: getToken() });
  },
};

export default BackendService;
