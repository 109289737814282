import { useTranslation } from "react-i18next";
import { useCustomDispatch } from "store/useStore";
import { appActions } from "store/app";

import Button from "./Button";

import "./Header.scss";

export default function Header() {
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  function onLogOutClick() {
    dispatch(appActions.setToken(""));
  }

  return (
    <div className="header">
      <h1>{t("Units")}</h1>
      <Button theme="text" onClick={onLogOutClick}>
        {t("LogOut")}
      </Button>
    </div>
  );
}
