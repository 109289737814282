import { useTranslation } from "react-i18next";

import "./Spinner.scss";

export default function Spinner({ loadingMessageKey }: { loadingMessageKey?: string }) {
  const { t } = useTranslation();

  return (
    <div className="spinner-wrapper">
      <div className="spinner-background"></div>
      <div className="spinner-content">
        <div className="spinner"></div>
        {!!loadingMessageKey && <p className="information-text">{t(loadingMessageKey)}</p>}
      </div>
    </div>
  );
}
