import { ReactNode } from "react";

export default function Label({ label, children }: { label: string; children: ReactNode }) {
  return (
    <div className="with-label">
      <label className="label">{label}</label>
      {children}
    </div>
  );
}
